export enum ACL {
    WITHOUT_PRIVILEGE = '',
    ADJUDICACION_LISTAR_ADJUDICACION = 'ADJUDICACION_LISTAR_ADJUDICACION',
    ADMIN = 'ADMIN',
    ASUNTO_LLAMADO_LISTAR = 'ASUNTO_LLAMADO_LISTAR',
    ASUNTO_LLAMADO_MODIFICAR = 'ASUNTO_LLAMADO_MODIFICAR',
    AUDITORIA_CARGAR = 'AUDITORIA_CARGAR',
    AUDITORIA_LISTAR = 'AUDITORIA_LISTAR',
    AUDITORIA_MODIFICAR = 'AUDITORIA_MODIFICAR',
    BAJA_LISTAR__PENDIENTE_BAJA_PROCESAR = 'BAJA_LISTAR__PENDIENTE_BAJA_PROCESAR',
    BAJA_LISTAR__PENDIENTE_BAJA_RESTAURAR = 'BAJA_LISTAR__PENDIENTE_BAJA_RESTAURAR',
    BAJA_LISTAR_PENDIENTE_BAJA = 'BAJA_LISTAR_PENDIENTE_BAJA',
    BAJA_LISTAR_TITULO_ADEUDADO = 'BAJA_LISTAR_TITULO_ADEUDADO',
    BAJA_LISTAR_TITULO_ADEUDADO_CARGAR = 'BAJA_LISTAR_TITULO_ADEUDADO_CARGAR',
    CAMPAÑA_LISTAR = 'CAMPAÑA_LISTAR',
    CAMPAÑA_LISTAR_LLAMADOS = 'CAMPAÑA_LISTAR_LLAMADOS',
    CLIENTE_BUSCAR = 'CLIENTE_BUSCAR',
    CLIENTE_CARGAR = 'CLIENTE_CARGAR',
    CLIENTE_MODIFICAR = 'CLIENTE_MODIFICAR',
    COBRANZA_LISTAR = 'COBRANZA_LISTAR',
    COMPRADO_CARGAR = 'COMPRADO_CARGAR',
    COMPRADO_LISTAR = 'COMPRADO_LISTAR',
    DEBITO_AUTOMATICO_CARGAR = 'DEBITO_AUTOMATICO_CARGAR',
    DEBITO_AUTOMATICO_LISTAR = 'DEBITO_AUTOMATICO_LISTAR',
    DEUDOR_LISTAR_ATRASADO = 'DEUDOR_LISTAR_ATRASADO',
    DEUDOR_LISTAR_CAIDO = 'DEUDOR_LISTAR_CAIDO',
    DEUDOR_LISTAR_LEGAL = 'DEUDOR_LISTAR_LEGAL',
    DEUDOR_LISTAR_MOROSO = 'DEUDOR_LISTAR_MOROSO',
    DEUDOR_LISTAR_OPERACION = 'DEUDOR_LISTAR_OPERACION',
    EMITIDO_LISTAR_EMITIDO = 'EMITIDO_LISTAR_EMITIDO',
    ENCOMIENDA_CARGAR = 'ENCOMIENDA_CARGAR',
    ENCOMIENDA_LISTAR = 'ENCOMIENDA_LISTAR',
    ENCOMIENDA_MODIFICAR = 'ENCOMIENDA_MODIFICAR',
    IMPRESION_BUSCAR = 'IMPRESION_BUSCAR',
    INGRESO_LISTAR_INGRESO = 'INGRESO_LISTAR_INGRESO',
    INGRESO_LISTAR_INGRESO_ADJUDICADO_POR_LICITACION = 'INGRESO_LISTAR_INGRESO_ADJUDICADO_POR_LICITACION',
    INGRESO_LISTAR_INGRESO_FUERA_DE_TERMINO = 'INGRESO_LISTAR_INGRESO_FUERA_DE_TERMINO',
    INTERESADO_CARGAR = 'INTERESADO_CARGAR',
    INTERESADO_LISTAR = 'INTERESADO_LISTAR',
    LLAMADO_ASIGNADO_LISTAR = 'LLAMADO_ASIGNADO_LISTAR',
    LLAMADO_ASIGNADO_LISTAR_ASIGNADOS = 'LLAMADO_ASIGNADO_LISTAR_ASIGNADOS',
    LLAMADO_ASIGNADO_LISTAR_PROMESA = 'LLAMADO_ASIGNADO_LISTAR_PROMESA',
    LLAMADO_CARGAR = 'LLAMADO_CARGAR',
    LLAMADO_LISTAR = 'LLAMADO_LISTAR',
    LLAMADO_RESPUESTA = 'LLAMADO_RESPUESTA',
    NOTIFICACION = 'NOTIFICACION',
    OBLEA_CARGAR = 'OBLEA_CARGAR',
    OBLEA_LISTAR = 'OBLEA_LISTAR',
    OBLEA_MODIFICAR = 'OBLEA_MODIFICAR',
    OPERACION_CARGAR = 'OPERACION_CARGAR',
    OPERACION_LISTAR = 'OPERACION_LISTAR',
    PAGOS_COMPROBANTES = 'PAGOS_COMPROBANTES',
    PANEL_TITULO_PRODUCCION_MENSUAL = 'PANEL_TITULO_PRODUCCION_MENSUAL',
    PERMISO_BORRAR_ROL = 'PERMISO_BORRAR_ROL',
    PERMISO_CARGAR_ROL = 'PERMISO_CARGAR_ROL',
    PERMISO_LISTAR_ROL = 'PERMISO_LISTAR_ROL',
    PERMISO_LISTAR_USUARIO = 'PERMISO_LISTAR_USUARIO',
    PERMISO_MODIFICAR_ROL = 'PERMISO_MODIFICAR_ROL',
    PERMISO_MODIFICAR_USUARIO = 'PERMISO_MODIFICAR_USUARIO',
    PLAN_BORRAR = 'PLAN_BORRAR',
    PLAN_CARGAR = 'PLAN_CARGAR',
    PLAN_LISTAR = 'PLAN_LISTAR',
    PRODUCCION_MENSUAL_LISTAR = 'PRODUCCION_MENSUAL_LISTAR',
    RESCATE_LISTAR_RESCATE = 'RESCATE_LISTAR_RESCATE',
    SORTEO_CARGAR = 'SORTEO_CARGAR',
    SORTEO_LISTAR = 'SORTEO_LISTAR',
    SUSCRIPCION_CARGAR = 'SUSCRIPCION_CARGAR',
    SUSCRIPCION_LISTAR = 'SUSCRIPCION_LISTAR',
    SUSCRIPCION_MARCAR_RECIBIDAS = 'SUSCRIPCION_MARCAR_RECIBIDAS',
    TITULO_BUSCAR = 'TITULO_BUSCAR',
    TITULO_CARGAR = 'TITULO_CARGAR',
    TITULO_LISTAR = 'TITULO_LISTAR',
    TITULO_MODIFICAR = 'TITULO_MODIFICAR',
    TITULOS_LISTAR_EXPORTAR_ACTIVOS = 'TITULOS_LISTAR_EXPORTAR_ACTIVOS',
    USUARIO_CARGAR = 'USUARIO_CARGAR',
    USUARIO_LISTAR = 'USUARIO_LISTAR',
    USUARIO_LOGIN = 'USUARIO_LOGIN',
    USUARIO_MODIFICAR = 'USUARIO_MODIFICAR',
    VACACIONES_CARGAR = 'VACACIONES_CARGAR',
    VACACIONES_LISTAR = 'VACACIONES_LISTAR',
    VIGENTE_LISTAR_VIGENTE = 'VIGENTE_LISTAR_VIGENTE',
    VIGENTE_LISTAR_VIGENTE_SORTEO = 'VIGENTE_LISTAR_VIGENTE_SORTEO',
    VIGENTE_LISTAR_VIGENTE_VALOR_NOMINAL = 'VIGENTE_LISTAR_VIGENTE_VALOR_NOMINAL',
    WEB_PODERSA = 'WEB_PODERSA',
    TITULO_BUSCAR_BOTON_OBLEA_LISTAR = 'TITULO_BUSCAR_BOTON_OBLEA_LISTAR',
    LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_FILTRO_USUARIO = 'LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_FILTRO_USUARIO',
    LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_BOTON_REASIGNAR = 'LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_BOTON_REASIGNAR'
}
