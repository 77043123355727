import { Component, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_DATEPICKER_FORMATS } from './shared/constants/global.constant';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEsAr, 'es-Ar');

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styles: [],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-Ar' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATEPICKER_FORMATS }
  ]
})
export class AppComponent { }
