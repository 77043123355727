import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { ACL } from 'src/app/shared/constants/acl.model';

export interface PAYLOAD {
  sub: string;
  nombre: string;
  aceptoTerminos: string;
  cambiarClave: string;
  auth: any;
  iat: number;
  exp: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private route: ActivatedRoute) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.jwtValid()) {
      this.router.navigate([
        '/login',
        this.router.getCurrentNavigation().finalUrl.queryParams
      ]);
      return false;
    }

    if (next.data.privileges && !this.hasACL(next.data.privileges)) {
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  jwtDecode(token: string): PAYLOAD {
    return JSON.parse(atob(token.split('.')[1]));
  }

  jwtNotExpired(token: string): boolean {
    return Date.now() < this.jwtDecode(token).exp * 1000;
  }

  jwtValid(): boolean {
    const token = localStorage.getItem('userToken');
    return token != null && this.jwtNotExpired(token);
  }

  hasACL(acl: string[]): boolean {
    const storedNames: string[] = JSON.parse(localStorage.getItem('acls'));
    storedNames.push(ACL.WITHOUT_PRIVILEGE);
    return storedNames.some(
      permiso => acl.includes(permiso) || permiso === 'ADMIN'
    );
  }
}
