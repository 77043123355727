import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/usuarios/iniciar-sesion') !== -1 || req.url.indexOf('/usuarios/reset-password') !== -1) {
      return next.handle(req.clone());
    }
    if (localStorage.getItem('userToken') != null) {
      const clonedreq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + localStorage.getItem('userToken')
        )
      });
      return next.handle(clonedreq).pipe(
        tap(
          // Succeeds when there is a response; ignore other events
          event => {},
          // Operation failed; error is an HttpErrorResponse
          error => {
            if (error.status === 500) {
              this.router.navigateByUrl('login');
            }
          }
        )
      );
    } else {
      this.router.navigateByUrl('login');
    }
  }
}
