// Table pageSizeOptions
export const PAGE_SIZE_OPTIONS_10: number[] = [10, 20];
export const PAGE_SIZE_OPTIONS_20: number[] = [10, 20];
export const PAGE_SIZE_OPTIONS: number[] = [20, 50, 100];


export const MY_DATEPICKER_FORMATS = {
    parse: {
        dateInput: 'L'
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
